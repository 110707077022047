import Image from 'next/image'

import Rating from '../../Rating'
import Typography from '../../Typography'
import { numberFormat } from 'src/common/utils'

import ButtonLink from 'src/components/shared/ButtonLink'

const ReviewWrapper = ({ link, onClick, children }) => (
  <>
    {link && (
      <ButtonLink
        href={link}
        className="relative mt-1 text-center"
        onClick={onClick}
        target="blank"
      >
        {children}
      </ButtonLink>
    )}
    {!link && <div className="mt-1 text-center">{children}</div>}
  </>
)

const DynamicMarkers = ({
  id,
  image,
  alt,
  rating,
  totalReviews,
  totalStars,
  color,
  link,
  onClick,
}) => {
  return (
    <div className="flex w-full flex-col items-center">
      <Image
        src={'https:' + image.fields.file.url}
        alt={alt}
        width={0}
        height={0}
        sizes="100vw"
        style={{
          height: id?.toLowerCase() === 'bbb' ? 80 : 25,
          maxWidth: '100%',
          width: 'auto',
        }}
      />
      {id?.toLowerCase() !== 'bbb' && (
        <>
          <Rating
            rating={rating}
            totalStars={totalStars}
            totalReviews={totalReviews}
            filledStarColor={color}
          />
          <ReviewWrapper link={link} onClick={onClick}>
            <Typography component="span" variant="bodySmallBold">
              {rating.toFixed(1)} |{' '}
            </Typography>
            {link && (
              <strong className="hover:underline">
                <Typography component="span" variant="bodySmallBold">
                  {numberFormat(totalReviews)}
                  {'+ '}
                </Typography>
                <Typography component="span" variant="bodySmall">
                  reviews
                </Typography>
              </strong>
            )}
            {!link && (
              <>
                <Typography component="span" variant="bodySmallBold">
                  {numberFormat(totalReviews)}
                  {'+ '}
                </Typography>
                <Typography component="span" variant="bodySmall">
                  reviews
                </Typography>
              </>
            )}
          </ReviewWrapper>
        </>
      )}
    </div>
  )
}

export default DynamicMarkers
